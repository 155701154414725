import styles from './Caution.module.css';

function Caution() {
  return (
    <div className={styles.caution}>
      <p>
        ※ 본 홈페이지에 표기된 세대별 면적은 전용면적 기준으로 소수점 넷째
        자리까지 표현되며, 면적 계산상 소수점 다섯째 자리에서 정리하는 방식으로
        인해 연면적과 전체 계약면적과는 소수점 이하에서 약간의 오차가 생길 수
        있으며, 면적은 사업계획승인 변경 등으로 실 시공시 변경될 수 있습니다.
      </p>
      <p>
        ※ 평면 및 가구, 마감재, 디자인, 사양, 색상, 제품품목 등은 입주자
        모집공고 이후 공개되는 견본주택 기준으로 시공됩니다. 견본주택에 표현되지
        않은 기타 공용부의 경우, 사용승인도서를 기준으로 시공됩니다.
      </p>
      <p>
        ※ 상세한 유상옵션 안내 및 가구 유상옵션품목과 마감재 및 가전기기
        유상옵션품목은 계약시 견본주택에서 최종 확인하시기 바랍니다.
      </p>
      <p>※ 외부창호열림 방향은 변경될 수 있습니다.</p>
      <p>
        ※ 조명특화옵션 선택시 제공되는 식탁특화조명은 평면 선택에 따라
        설치위치가 변경될 수 있습니다.
      </p>
      <p>
        ※ 식탁특화조명은 보편적으로 식탁이 놓일 것으로 예상되는 위치에
        설치하였으며, 입주 후 개인의 사정에 따른 위치변경을 요구할 수 없습니다.
      </p>
    </div>
  );
}

export default Caution;
